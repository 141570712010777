// https://blog.bitsrc.io/why-are-react-scripts-a-big-deal-in-react-c3006326c0c2
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import App4 from './app4';
import App5 from './app5';
import App6 from './app6';
import App7 from './app7';

/* eslint-disable */
// ### Header Logo
const headerLogo = createRoot(document.getElementById('dsiheaderlogo'));
headerLogo.render(<StrictMode><App/></StrictMode>);

// ### Dropdown Menu Data
const dropdownmenu = createRoot(document.getElementById('dropdownmenu'));
dropdownmenu.render(<StrictMode><App4/></StrictMode>);

// ### Key Services Data
const dsikeyservices = createRoot(document.getElementById('dsikeyservices'));
dsikeyservices.render(<StrictMode><App5/></StrictMode>);

// ### Services Data
const dsiservices = createRoot(document.getElementById('dsiservices'));
dsiservices.render(<StrictMode><App6/></StrictMode>);

// ### News and Insights Data
const newsandinsights = createRoot(document.getElementById('newsandinsights'));
newsandinsights.render(<StrictMode><App7/></StrictMode>);
/* eslint-enable */