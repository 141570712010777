import News from './news';

/* eslint-disable */
export default function App7({ newsItems = News }) {
    return (
        <>
            {newsItems.map((newsItem, i) => (
                <div id={newsItem.id} class="swiper-slide swiper-slide h-auto py-3">
                    <article class="card p-md-3 p-2 border-0 shadow-sm card-hover-primary h-100 mx-2">
                        <div class="card-body pb-0">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <a href="#" class="badge fs-sm text-nav bg-secondary text-decoration-none position-relative zindex-2">{newsItem.header}</a>
                                <span class="fs-sm text-muted">{newsItem.date}</span>
                            </div>
                            <h3 class="h4">
                                <a href={newsItem.link} class="stretched-link" target="_blank">{newsItem.subject}</a>
                            </h3>
                            <p class="mb-0">{newsItem.details}</p>
                            </div>
                            <div class="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                            <div class="d-flex align-items-center me-3">
                                <i class="bx bx-like fs-lg me-1"></i>
                                <span class="fs-sm">{newsItem.like}</span>
                            </div>
                            <div class="d-flex align-items-center me-3">
                                <i class="bx bx-comment fs-lg me-1"></i>
                                <span class="fs-sm">{newsItem.comment}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <i class="bx bx-share-alt fs-lg me-1"></i>
                                <span class="fs-sm">{newsItem.share}</span>
                            </div>
                        </div>
                    </article>
                </div>
            ))}
        </>);
};
/* eslint-enable */