
import './styles.css';
import Pages from './pages.json';

/* eslint-disable */
export default function App4({ pages = Pages }) {
  return (
    <>
      <div id="navbarNav" class="offcanvas offcanvas-end bg-dark">
        <div class="offcanvas-header border-bottom border-light">
          <h5 class="offcanvas-title text-white">Menu</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a href="#" class="nav-link dropdown-toggle active" data-bs-toggle="dropdown" aria-current="page">Services</a>
              <div class="dropdown-menu dropdown-menu-dark p-0">
                <div class="d-lg-flex">
                  <div class="mega-dropdown-column d-flex justify-content-center align-items-center rounded-3 rounded-end-0 px-0" style={{ margin: '-1px', backgroundColor: '#f3f6ff' }}>
                    <img src="assets/img/landing/intro/hero/screens01_1_1.png" alt="Landings"></img>
                  </div>
                  <div class="mega-dropdown-column pt-lg-3 pb-lg-4" style={{ width: '300px' }}>
                    <ul class="list-unstyled mb-0">
                      <li>Services</li>
                      {pages.map((page, i) => (
                        <li key={page.id}><a href={page.link} class={page.class}>{page.service}</a></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a href="contact.html" class="nav-link">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
/* eslint-enable */