import Service from './services';

/* eslint-disable */
export default function App6({ services = Service }) {
    return (
        <>
            <h2 class="h1 text-center pt-1 pt-md-3 pt-lg-4 pb-3 pb-md-4 mb-0 mb-lg-3">Services</h2>
            <div class="row row-cols-1 row-cols-md-2">
                {services.map((service, i) => (
                    <div class="col py-4 my-2 my-sm-3">
                        <a href={service.link} class="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                            <div class="card-body pt-3">
                            <div class="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                                <img src={service.logo} class="d-block m-1" width="40" alt="Icon"></img>
                            </div>
                            <h2 class="h4 d-inline-flex align-items-center">
                                {service.service}
                                <i class="bx bx-right-arrow-circle text-primary fs-3 ms-2"></i>
                            </h2>
                            <p class="fs-sm text-body mb-0">{service.description}</p>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </>);
};
/* eslint-enable */