// https://blog.bitsrc.io/why-are-react-scripts-a-big-deal-in-react-c3006326c0c2
// https://medium.com/@steve.2.farrington/how-to-extend-create-react-app-for-multi-page-applications-4f9c0fb0710c
// https://blog.logrocket.com/everything-you-need-know-about-react-scripts/

/* eslint-disable */
export default function App() {
  return (
      <a href="index.html" class="navbar-brand pe-3">
        <img src="/assets/img/dsi_logo_white.png"
          width="221"
          alt="Data Science Insights">
        </img>
      </a>
  );
}
/* eslint-enable */