import KeyService from './key-services';

/* eslint-disable */
export default function App5({ keyservices = KeyService }) {
    return (
        <>
            {keyservices.map((keyservice, i) => (
                <div class="swiper-slide">
                    <a href={keyservice.link} class="card-portfolio position-relative d-block rounded-3 overflow-hidden">
                        <span class="position-absolute top-0 start-0 w-100 h-100 zindex-1" style={{background: 'linear-gradient(180deg, rgba(17, 24, 39, 0.00) 35.56%, #111827 95.3%)'}}></span>
                        <div class="position-absolute bottom-0 w-100 zindex-2 p-4">
                            <div class="px-md-3">
                                <h3 class="h4 text-white mb-0">{keyservice.service}</h3>
                                <div class="card-portfolio-meta d-flex align-items-center justify-content-between">
                                <span class="text-white fs-xs text-truncate opacity-70 pe-3">{keyservice.description}</span>
                                <i class="bx bx-right-arrow-circle fs-3 text-gradient-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-img">
                            <img src={keyservice.image} alt="{keyservice.service}"></img>
                        </div>
                    </a>
                </div>
            ))}
        </>);
};
/* eslint-enable */